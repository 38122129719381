/* eslint-disable react/jsx-one-expression-per-line */
import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const StandOut = styled.section`
  .stand-out {
    align-items: center;
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(1, 1fr);
    margin: 0 0 80px;

    &__image {
      text-align: center;
    }
  }

  h3 {
    span {
      display: block;
    }
  }

  ul {
    list-style: none;
    padding: 0;
  }

  @media only screen and (min-width: 800px) {
    .stand-out {
      grid-template-columns: repeat(2, 1fr);
    }

    ul {
      margin: 0 auto;
      max-width: 530px;
    }
  }

  @media only screen and (min-width: 1400px) {
    ul {
      padding: 25px 50px;
    }
  }
`;

const StandOutContent = () => {
  const data = useStaticQuery(graphql`
    {
      old_coins: file(relativePath: {regex: "/srebrne-i-zlote-monety/"}) {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            placeholder: BLURRED
            width: 580
          )
        }
      }
    }
  `);

  return (
    <StandOut>
      <h2 className="text-center"><span>Co nas wyróżnia?</span></h2>

      {/* Marcin Zamorski -> Portal Numizmatyczny (https://www.facebook.com/groups/portalnumizmatyczny/posts/3564930683755185/) */}

      <div className="stand-out">
        <div className="stand-out__content">
          <ul>
            <li>
              <h3 style={{ marginTop: '20px' }}>
                <span>Indywidualne</span>
                <span>podejście do klienta</span>
              </h3>
              <p>Jesteśmy tu dla Ciebie! Nasze podejście jest oparte na&nbsp;indywidualnych potrzebach klienta.</p>
              <p>Niezależnie od tego, czy chcesz sprzedać całą kolekcję czy tylko kilka monet lub banknotów, zapewniamy profesjonalną obsługę i&nbsp;pełne wsparcie.</p>
            </li>

            <li>
              <h3>
                <span>Wsparcie dla początkujących</span>
                <span>i&nbsp;zaawansowanych numizmatyków</span>
              </h3>
              <p>Niezależnie od poziomu doświadczenia, pomożemy Ci&nbsp;zacząć przygodę z&nbsp;numizmatyką lub rozwinąć Twoją&nbsp;istniejącą kolekcję.</p>
            </li>
          </ul>
        </div>

        <div className="stand-out__image">
          <GatsbyImage
            image={getImage(data.old_coins)}
            alt="srebrne i złote monety kolekcjonerskie"
          />
        </div>
      </div>
    </StandOut>
  );
};

export default StandOutContent;
