import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const RandomImage = () => {
  const [image, setImage] = useState(null);

  const data = useStaticQuery(graphql`
    query {
      allFile(filter: {extension: {eq: "jpg"}, name: {in: ["dworzec-w-olsztynie","kleebergstrasse","olsztyn-11-listopada","olsztyn-glowny-rynek","olsztyn-gmach-sadu-okregowego","stara-pocztowka"]}}) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 600)
          }
        }
      }
    }
  `);

  useEffect(() => {
    const images = data.allFile.nodes;
    const randomIndex = Math.floor(Math.random() * images.length);
    setImage(images[randomIndex]);
  }, [data.allFile.nodes]);

  if (!image) return null;

  // Ustalanie tekstu alternatywnego na podstawie nazwy pliku
  let altText;
  switch (image.name) {
    case 'dworzec-w-olsztynie':
      altText = 'Dworzec Główny w Olsztynie';
      break;
    case 'olsztyn-11-listopada':
      altText = 'Ulica 11-listopada';
      break;
    case 'kleebergstrasse':
      altText = 'Kleebergstrasse';
      break;
    case 'olsztyn-glowny-rynek':
      altText = 'Olsztyn - główny rynek';
      break;
    case 'olsztyn-gmach-sadu-okregowego':
      altText = 'Gmach Sądu Okręgowego';
      break;
    case 'stara-pocztowka':
      altText = 'stara pocztówka z Olsztyna';
      break;
    default:
      altText = 'Inny obraz';
  }

  return (
    <GatsbyImage image={getImage(image.childImageSharp.gatsbyImageData)} alt={altText} />
  );
};

export default RandomImage;
