/* eslint-disable react/jsx-one-expression-per-line */
import * as React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Intro = styled.section`
  margin: 0 0 40px;

  figure {
    margin: 20px 0 40px;
    text-align: center;
  }

  .columns {
    display: grid;

    &--invert {
      .col--6 {
        &:nth-child(2) {
           @media only screen and (max-width: 819px) {
            display: none;
          }
        }
      }
    }
  }

  @media only screen and (min-width: 820px) {
    margin-top: -20px;

    .columns {
      align-items: center;
      grid-gap: 50px;
      grid-template-columns: repeat(2, 1fr);

      &--invert {
        .col--6 {
          &:nth-child(2) {
            order: -1;
          }
        }
      }

      &--top {
        align-items: flex-start;
      }
    }
  }
`;

const IntroSection = () => {
  const data = useStaticQuery(graphql`
    {
      gulden: file(relativePath: {regex: "/5-guldenow/"}) {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            placeholder: BLURRED
            width: 452
          )
        }
      }
      buying_coins: file(relativePath: {regex: "/skup-srebrnych-monet/"}) {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            placeholder: BLURRED
            width: 435
          )
        }
      }
    }
  `);

  return (
    <Intro>
      <div className="columns">
        <div className="col--6">
          <h1>
            <span>Skup monet na&nbsp;terenie</span>
            <span>Olsztyna i&nbsp;okolic</span>
          </h1>

          <p>Skup starych monet w&nbsp;Olsztynie narodził się z&nbsp;wieloletniej pasji do numizmatyki. Pasja z&nbsp;czasem przerodziła się w&nbsp;sposób na życie.</p>

          <p>Z&nbsp;poczucia misji od wielu lat zajmujemy się profesjonalną i&nbsp;rzetelną wyceną oraz skupem monet, banknotów, medali i&nbsp;odznaczeń.</p>

          <p>Oferujemy najwyższą jakość usług, na które składają się korzystne ceny, natychmiastowa płatność oraz całkowite bezpieczeństwo transakcji.</p>
        </div>
        <div className="col--6">
          <figure>
            <GatsbyImage
              image={getImage(data.gulden)}
              alt="srebrne i złote monety XIX i XX wiek"
              loading="eager"
            />
            <figcaption className="no-marg">
              5 rubli 1847, podwójny Louis 1786, <span className="nowrap">5&nbsp;guldenów 1932&nbsp;(żuraw)</span>
            </figcaption>
          </figure>
        </div>
      </div>

      <div className="columns columns--invert">
        <div className="col--6">
          <p>
            W naszym <Link to="/skup-monet-i-banknotow/">skupie monet</Link> w&nbsp;Olsztynie bezpłatnie wycenisz i&nbsp;sprzedasz niemal wszystkie monety i&nbsp;banknoty oraz medale, odznaczenia czy inne numizmaty.
          </p>

          <p>
            Doskonałe zorientowanie na rynku sprawia, że skupujemy nawet najrzadsze okazy. Jesteśmy zainteresowani zakupem zarówno pojedynczych egzemplarzy, jak i&nbsp;całych kolekcji.
          </p>
        </div>
        <div className="col--6">
          <figure>
            <GatsbyImage
              image={getImage(data.buying_coins)}
              alt="złote monety polskie i zagraniczne"
            />
          </figure>
        </div>
      </div>
    </Intro>
  );
};

export default IntroSection;
