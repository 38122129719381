/* eslint-disable react/jsx-one-expression-per-line */
import * as React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
// import { Link } from 'gatsby';
// import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import RandomImage from '../RandomImage';

const ListGrid = styled.ul`
  list-style: none;
  margin: 0 0 40px;
  padding: 0;

  > li {
    align-items: center;
    border-top: 1px solid rgba(199,87,132,0.25);
    display: grid;
    grid-gap: 40px;
    padding: 50px 0;

    &:first-child {
      border-top: 0;
    }
  }

  .list-grid__content {
    h3 {
      margin-top: 0;

      span {
        display: block;
      }
    }

    ul {
      margin: 0 0 2rem;
    }
  }

  .list-grid__image {
    text-align: center;

    .gatsby-image-wrapper {
      max-width: 100%;
    }

    figure {
      margin: 0;
    }
  }

  @media only screen and (min-width: 640px) {
    > li {
      align-items: center;
      display: grid;
      grid-gap: 40px;
      grid-template-columns: repeat(2, 1fr);
      margin: 0 0 20px;

      &:nth-child(even) {
        .list-grid__image {
          order: -1;
        }
      }
    }
  }

  @media only screen and (min-width: 1200px) {
    .list-grid__content {
      padding: 15px 25px;
    }
  }

  @media only screen and (min-width: 1400px) {
    .list-grid__content {
      padding: 25px 50px;
    }
  }
`;

const ListColumns = styled.ul`
  list-style: none;
  display: grid;
  /* grid-gap: 32px; */
  gap: 0 32px;
  grid-template-columns: repeat(1, 1fr);
  margin: 50px 0 80px;
  padding: 0;

  .list-columns__item {
    border-top: 1px solid rgba(199,87,132,0.25);
    display: flex;
    flex-direction: column;
    padding: 50px 0;
    /* grid-gap: 50px; */

    &:first-child {
      border: 0;
      padding-top: 0;
    } 

    h3 {
      margin: 24px 0 16px;
    }

    ul {
      list-style: disc;
      padding: 0 0 0 30px;
    }
  }

  .list-columns__image {
    order: -1;

    figure {
      margin: 0;

      .gatsby-image-wrapper {
        width: 100%;
      }
    }
  }

  @media only screen and (min-width: 640px) {
    gap: 64px 32px;
    grid-template-columns: repeat(2, 1fr);

    .list-columns__item {
      border: 0;
      padding: 0;
    }
  }

  @media only screen and (min-width: 960px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const WhatWeBuy = () => {
  const data = useStaticQuery(graphql`
    {
      money: file(relativePath: {regex: "/monety-2-rp.png/"}) {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            placeholder: NONE
            width: 540
          )
        }
      }
      polish_banknotes: file(relativePath: {regex: "/20-marek-1916/"}) {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            placeholder: BLURRED
            width: 540
          )
        }
      }
      military: file(relativePath: {regex: "/odznaczenia-wojskowe/"}) {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            placeholder: BLURRED
            width: 540
          )
        }
      }
      stamps: file(relativePath: {regex: "/polskie-znaczki/"}) {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            placeholder: BLURRED
            width: 365
          )
        }
      }
      old_documents: file(relativePath: {regex: "/stare-dokumenty/"}) {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            placeholder: BLURRED
            width: 365
          )
        }
      }
    }
  `);

  return (
    <>
      <section>
        <h2 className="text-center">
          <span>Czym się zajmujemy?</span>
        </h2>

        <ListGrid>
          <li className="list-grid__item">
            <div className="list-grid__content">
              <h3><Link to="/skup-monet-olsztyn/">Wycena i skup starych monet</Link></h3>

              <p>Bezpłatnie wyceniamy stare monety polskie i&nbsp;zagraniczne w&nbsp;oparciu o&nbsp;aktualne ceny rynkowe.</p>

              <p>Skupujemy głównie:</p>

              <ul>
                <li>polskie monety obiegowe</li>
                <li><Link to="/skup-monet-ii-rp/" title="Skup srebrnych monet 2 RP">monety II RP</Link></li>
                <li>srebrne i złote monety</li>
                <li>monety kolekcjonerskie</li>
                <li>monety próbne</li>
                <li>monety w gradingu NGC i PCGS</li>
              </ul>

              <Link
                to="/skup-monet-olsztyn/"
                className="btn btn--outline"
              >
                czytaj więcej
              </Link>
            </div>

            <div className="list-grid__image">
              <figure>
                {/* Marcin Zamorski -> Portal Numizmatyczny */}
                <GatsbyImage
                  image={getImage(data.money)}
                  alt="polskie srebrne monety obiegowe z okresu międzywojennego 2 RP"
                />
              </figure>
            </div>
          </li>

          <li className="list-grid__item">
            <div className="list-grid__content">
              <h3><Link to="/skup-starych-banknotow/">Skup starych banknotów</Link></h3>

              <ul>
                <li>skupujemy banknoty polskie i&nbsp;zagraniczne</li>
                <li>wzory banknotów</li>
                <li>banknoty PRL</li>
                <li>banknoty kolekcjonerskie emitowane przez NBP</li>
                <li>banknoty w&nbsp;gradingu PMG</li>
                <li>polskie marki - emisja 1916 r. (jenerał/Generał)</li>
                <li>Bilety Skarbowe Insurekcji Kościuszkowskiej</li>
              </ul>

              <Link
                to="/skup-starych-banknotow/"
                className="btn btn--outline"
              >
                czytaj więcej
              </Link>
            </div>

            <div className="list-grid__image">
              <figure>
                <GatsbyImage
                  image={getImage(data.polish_banknotes)}
                  alt="Polskie banknoty z lat 1830–1939"
                />
              </figure>
            </div>
          </li>

          <li className="list-grid__item">
            <div className="list-grid__content">
              <h3>
                <Link to="/skup-medali-orderow-odznaczen/">
                  <span>Wycena medali</span>
                  <span>i odznaczeń wojskowych</span>
                </Link>
              </h3>
              <p>Chętnie skupujemy medale okolicznościowe, ordery i&nbsp;odznaczenia wojskowe wraz z&nbsp;dokumentami nadania.</p>

              <Link
                to="/skup-medali-orderow-odznaczen/"
                className="btn btn--outline"
              >
                czytaj więcej
              </Link>
            </div>

            <div className="list-grid__image">
              <figure>
                <GatsbyImage
                  image={getImage(data.military)}
                  alt="Polskie odznaczenia wojskowe"
                />
              </figure>
            </div>
          </li>
        </ListGrid>
      </section>

      <section>
        <h2 className="text-center">
          <span>Dodatkowa działalność</span>
        </h2>

        <ListColumns>
          <li className="list-columns__item">
            <div className="list-columns__desc">
              <h3><Link to="/stare-pocztowki/">Stare pocztówki</Link></h3>
              <p>
                Chętnie skupujemy stare, przedwojenne <strong>pocztówki związane z&nbsp;Olsztynem</strong>, na których znajdują się zabytkowa architektura miasta z&nbsp;okresu międzywojennego lub&nbsp;starsze.
              </p>

              <Link
                to="/stare-pocztowki/"
                className="btn btn--outline"
              >
                czytaj więcej
              </Link>
            </div>

            <div className="list-columns__image">
              <figure>
                <RandomImage />
              </figure>
            </div>
          </li>

          <li className="list-columns__item">
            <div className="list-columns__desc">
              <h3>Historyczne dokumenty</h3>
              <p style={{ marginBottom: '2px' }}>Jesteśmy zainteresowani skupem:</p>

              <ul>
                <li>patentów oficerskich</li>
                <li>dokumentów nadania odznaczeń</li>
                <li>legitymacji wojskowych</li>
              </ul>
            </div>

            <div className="list-columns__image">
              <figure>
                <GatsbyImage
                  image={getImage(data.old_documents)}
                  alt="stare dokumenty"
                />
              </figure>
            </div>
          </li>

          <li className="list-columns__item">
            <div className="list-columns__desc">
              <h3>Filatelistyka - skup znaczków</h3>
              <p>Walory filatelistyczne, które cieszą się wysokimi cenami, to te unikatowe - emitowane w ograniczonych ilościach.</p>

              <p>Rekordzistą wśród najcenniejszych znaczków pocztowych jest British Guiana 1c Magenta. Jest znany tylko 1&nbsp;egzemplarz. W 2014 roku został on wylicytowany za rekordową sumę 9,48 miliona dolarów.</p>
            </div>

            <div className="list-columns__image">
              <figure>
                <GatsbyImage
                  image={getImage(data.stamps)}
                  alt="stare polskie znaczki"
                />
              </figure>
            </div>
          </li>
        </ListColumns>
      </section>
    </>
  );
};

export default WhatWeBuy;
